import React, { Component } from 'react';

class Blogs extends Component {
  componentDidMount() {
    window.location.replace('https://blog.maistro.com');
  }

  render() {
    return <div />;
  }
}

export default Blogs;
